export function getUrl(pathname: string, query: Record<string, string> = {}) {
  const url = new URL(pathname, window.location.origin);

  Object.entries(query).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });

  // Preserve existing query params
  for (const [key, value] of new URLSearchParams(window.location.search)) {
    url.searchParams.append(key, value);
  }

  return url.toString();
}
