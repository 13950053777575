'use client';

import { FirebaseProvider, useFirebase } from './firebase-provider';
import {
  GoogleAuthProvider,
  IdTokenResult,
  User,
  UserCredential,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  sendSignInLinkToEmail as firebaseSendSignInLinkToEmail,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  updateProfile as firebaseUpdateProfile,
  getAuth,
  signInWithEmailLink,
  signInWithPopup,
} from 'firebase/auth';
import { Suspense, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { redirect, useRouter, useSearchParams } from 'next/navigation';

import Cookies from 'js-cookie';
import { RedirectType } from 'next/dist/client/components/redirect';
import { getUrl } from '../utils/get-url';
import localforage from 'localforage';
import { toast } from '../components/toast';
import { wait } from '../utils/wait';
import { z } from 'zod';

type UpdateProfileArgs = Parameters<typeof firebaseUpdateProfile>[1];

type AuthValue = {
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential | undefined>;
  getStoredEmail: () => Promise<string | null>;
  idTokenResult: IdTokenResult | null;
  isLoading: boolean;
  isPartner: boolean;
  refreshToken: () => Promise<string | undefined>;
  setStoredEmail: (email: string) => Promise<string>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  sendSignInLinkToEmail: (email: string) => Promise<void>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential | undefined>;
  signInWithGoogle: () => Promise<UserCredential | undefined>;
  signOut(redirectTo?: string): Promise<void>;
  user: User | null;
  userId?: string;
  updateProfile(profile: UpdateProfileArgs): Promise<void>;
};

const REDIRECT_URL = '/user/login';
const EMAIL_KEY = 'mars-login-email';
const DEFAULT_AUTH_VALUE: AuthValue = {
  createUserWithEmailAndPassword: async () => undefined,
  getStoredEmail,
  idTokenResult: null,
  isLoading: true,
  isPartner: false,
  refreshToken: async () => undefined,
  setStoredEmail,
  sendPasswordResetEmail: async () => undefined,
  sendSignInLinkToEmail: async () => undefined,
  signInWithEmailAndPassword: async () => undefined,
  signInWithGoogle: async () => undefined,
  signOut: async () => undefined,
  user: null,
  updateProfile: async () => undefined,
};

const AuthContext = createContext(DEFAULT_AUTH_VALUE);

export function useAuth() {
  return useContext(AuthContext);
}

type AuthProviderProps = {
  children: React.ReactNode;
  redirectToLogin?: boolean;
};

export function AuthProvider(props: AuthProviderProps) {
  return (
    <FirebaseProvider>
      <Suspense>
        <AuthProviderConnected {...props} />
      </Suspense>
    </FirebaseProvider>
  );
}

function AuthProviderConnected({ children, redirectToLogin }: AuthProviderProps) {
  const [user, setUser] = useState<AuthValue['user']>();
  const [idTokenResult, setIdTokenResult] = useState<AuthValue['idTokenResult']>(null);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isEmailSignInLoading, setIsEmailSignInLoading] = useState(true);
  const isLoading = isUserLoading || isEmailSignInLoading;
  const { app } = useFirebase();
  const searchParams = useSearchParams();
  const router = useRouter();
  const isPartner = !!idTokenResult?.claims?.partner;

  const createUserWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      if (app) {
        const auth = getAuth(app);

        return firebaseCreateUserWithEmailAndPassword(auth, email, password);
      }
    },
    [app]
  );
  const sendPasswordResetEmail = useCallback(
    async (email: string) => {
      if (app) {
        const auth = getAuth(app);

        return firebaseSendPasswordResetEmail(auth, email);
      }
    },
    [app]
  );
  const sendSignInLinkToEmail = useCallback(
    async (email: string) => {
      if (app) {
        const auth = getAuth(app);

        await setStoredEmail(email);

        if (searchParams.get('mode') === 'signIn') {
          await signInWithEmailLink(auth, email, window.location.href).catch((e) =>
            toast({ key: 'sign-in-with-email-link-failed', title: 'Email link failed', description: 'Try again.' })
          );
        } else {
          return firebaseSendSignInLinkToEmail(auth, email, {
            handleCodeInApp: true,
            url: getUrl('/user/login/email'),
          });
        }
      }
    },
    [app, searchParams]
  );
  const signInWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      if (app) {
        const auth = getAuth(app);

        await setStoredEmail(email);

        return firebaseSignInWithEmailAndPassword(auth, email, password);
      }
    },
    [app]
  );
  const signInWithGoogle = useCallback(async () => {
    if (app) {
      const auth = getAuth(app);
      const provider = new GoogleAuthProvider();

      return signInWithPopup(auth, provider);
    }
  }, [app]);
  const signOut = useCallback(
    async (redirectTo = '/') => {
      if (app) {
        const auth = getAuth(app);

        await auth.signOut();

        router.push(redirectTo);
      }
    },
    [app, router]
  );
  const updateProfile = useCallback(
    async (args: UpdateProfileArgs) => {
      if (app) {
        const auth = getAuth(app);

        if (auth.currentUser) {
          await firebaseUpdateProfile(auth.currentUser, args);

          setUser(undefined);

          await wait(2000);

          setUser(getAuth(app)?.currentUser);
        } else {
          throw new Error('User is not signed in');
        }
      }
    },
    [app]
  );
  const refreshToken = useCallback(async () => {
    const auth = getAuth(app);

    return auth.currentUser?.getIdToken(true);
  }, [app]);

  useEffect(() => {
    if (app) {
      const auth = getAuth(app);

      auth.onAuthStateChanged(async (user) => {
        setUser(user);

        const decodedToken = await refreshToken();
        if (decodedToken) {
          Cookies.set('token', decodedToken, { expires: 7 });
        } else {
          Cookies.remove('token');
        }

        const idTokenResult = await auth.currentUser?.getIdTokenResult(true);
        setIdTokenResult(idTokenResult || null);
      });
    }
  }, [app, refreshToken]);

  useEffect(() => {
    if (redirectToLogin && !user && !isLoading && !isLoginPage()) {
      const url = new URL(REDIRECT_URL, window.location.origin);
      const urlSearchParams = new URLSearchParams([['redirectTo', window.location.pathname]]);

      url.search = urlSearchParams.toString();

      redirect(url.toString(), RedirectType.replace);
    }
  }, [isLoading, redirectToLogin, user]);

  useEffect(() => {
    if (user && isLoginPage()) {
      const url = new URL(window.location.href);
      const urlSearchParams = new URLSearchParams(url.search);
      const redirectTo = urlSearchParams.get('redirectTo');

      if (redirectTo) {
        redirect(redirectTo, RedirectType.replace);
      } else {
        redirect('/', RedirectType.replace);
      }
    } else if (typeof user !== 'undefined') {
      setIsUserLoading(false);
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      if (searchParams.get('mode') === 'signIn') {
        const storedEmail = await getStoredEmail();
        const parsed = z.string().email().safeParse(storedEmail);

        if (!parsed.success) {
          toast({ key: 'email-not-found', title: 'Email not found', description: 'Try again with a valid email.' });

          setIsEmailSignInLoading(false);
        } else if (app) {
          const auth = getAuth(app);
          const email = parsed.data;

          await signInWithEmailLink(auth, email, window.location.href);
        }
      } else {
        setIsEmailSignInLoading(false);
      }
    })();
  }, [app, searchParams]);

  return (
    <AuthContext.Provider
      value={{
        createUserWithEmailAndPassword,
        getStoredEmail,
        idTokenResult,
        isLoading,
        isPartner,
        refreshToken,
        setStoredEmail,
        sendPasswordResetEmail,
        sendSignInLinkToEmail,
        signInWithEmailAndPassword,
        signInWithGoogle,
        signOut,
        user: user || null,
        userId: user?.uid,
        updateProfile,
      }}
    >
      <>{children}</>
    </AuthContext.Provider>
  );
}

function isLoginPage() {
  return window.location.pathname.includes(REDIRECT_URL);
}

async function setStoredEmail(email: string) {
  return localforage.setItem(EMAIL_KEY, email);
}
async function getStoredEmail() {
  const email = await localforage.getItem(EMAIL_KEY);

  return z.string().email().nullable().parse(email);
}
