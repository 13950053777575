'use client';

import * as RadixToast from '@radix-ui/react-toast';

import { css, cx } from '../styled-system/css';

import { Box } from '../components/box';
import { SystemStyleObject } from '../styled-system/types';
import { typography } from '../components/typography';

type Css = { css?: SystemStyleObject };

function Root({ css: cssObject, ...props }: RadixToast.ToastProps & Css) {
  return (
    <RadixToast.Root
      {...props}
      className={css({
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        columnGap: '2',
        rowGap: '3',

        background: 'm3.inverseSurface',
        color: 'm3.inverseOnSurface',
        borderRadius: 'sm',
        fontSize: 'sm',
        paddingX: '4',
        paddingY: '3',
        width: '20rem',
        maxWidth: 'calc(100vw - 2rem)',
        ...cssObject,
      })}
    />
  );
}

function Title({ children, css: cssObject = {}, ...props }: RadixToast.ToastTitleProps & Css) {
  return (
    <RadixToast.Title
      className={cx(
        typography({ type: 'h6' }),
        css({ color: 'm3.inverseOnSurface !important', width: '100%', ...cssObject })
      )}
    >
      {children}
    </RadixToast.Title>
  );
}

function Description({ children, css: cssObject = {}, ...props }: RadixToast.ToastDescriptionProps & Css) {
  return (
    <RadixToast.Description
      className={cx(typography({ type: 'body2' }), css({ color: 'm3.inverseOnSurface !important', ...cssObject }))}
    >
      {children}
    </RadixToast.Description>
  );
}

function Action({ children, css: cssObject = {}, ...props }: RadixToast.ToastActionProps & Css) {
  return (
    <RadixToast.Action
      {...props}
      className={css({
        color: 'm3.inversePrimary',
        fontWeight: '500',
        cursor: 'pointer',

        ...cssObject,
      })}
    >
      {children}
    </RadixToast.Action>
  );
}

function Close({ children, css: cssObject = {}, ...props }: RadixToast.ToastCloseProps & Css) {
  return (
    <RadixToast.Close
      {...props}
      className={css({
        color: 'm3.inverseOnSurface',
        cursor: 'pointer',

        ...cssObject,
      })}
    >
      {children}
    </RadixToast.Close>
  );
}

function Actions({ children, css: cssObject = {} }: { children: React.ReactNode } & Css) {
  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'row-reverse',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'end',
        gap: '2',
        ...cssObject,
      }}
    >
      {children}
    </Box>
  );
}

function Viewport({ css: cssObject = {}, ...props }: RadixToast.ToastViewportProps & Css) {
  return <RadixToast.Viewport className={css(cssObject)} {...props} />;
}

export const Toast = {
  Provider: RadixToast.Provider,
  Viewport,
  Root,
  Title,
  Description,
  Action,
  Actions,
  Close,
};
