import { useCallback, useEffect, useRef } from 'react';

type EventBusListener = {
  event: string;
  callback: (event: { detail: unknown }) => void;
};

export function useEventBus() {
  const listeners = useRef<EventBusListener[]>([]);

  const dispatch = useCallback((event: EventBusListener['event'], data = {}) => {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  }, []);
  const remove = useCallback((event: EventBusListener['event'], callback: EventBusListener['callback']) => {
    document.removeEventListener(event, callback as unknown as EventListener);
  }, []);
  const on = useCallback(
    (event: EventBusListener['event'], callback: EventBusListener['callback']) => {
      listeners.current.push({ event, callback });

      document.addEventListener(event, callback as unknown as EventListener);

      return () => {
        const existingIndex = listeners.current.findIndex((l) => l.event === event && l.callback === callback);

        existingIndex !== -1 && listeners.current.splice(existingIndex, 1);

        remove(event, callback);
      };
    },
    [remove]
  );

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      listeners.current.forEach(({ event, callback }) => remove(event, callback));

      listeners.current = [];
    };
  }, [remove]);

  return { dispatch, on, remove };
}
