'use client';

import { FirebaseApp, FirebaseOptions, getApps, initializeApp } from 'firebase/app';
import { createContext, useContext, useEffect, useState } from 'react';

import { z } from 'zod';

const firebaseOptionsSchema = z.object({
  apiKey: z.string().default(process.env.NEXT_PUBLIC_FIREBASE_API_KEY ?? ''),
  authDomain: z.string().default(process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ?? ''),
  databaseURL: z.string().default(process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL ?? ''),
  projectId: z.string().default(process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ?? ''),
  storageBucket: z.string().default(process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET ?? ''),
  messagingSenderId: z.string().default(process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID ?? ''),
  appId: z.string().default(process.env.NEXT_PUBLIC_FIREBASE_APP_ID ?? ''),
  measurementId: z.string().default(process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID ?? ''),
});
type FirebaseValue = { app?: FirebaseApp };

const DEFAULT_FIREBASE_VALUE: FirebaseValue = {};

const FirebaseContext = createContext(DEFAULT_FIREBASE_VALUE);

export function useFirebase() {
  return useContext(FirebaseContext);
}

type FirebaseProviderProps = {
  children: React.ReactNode;
  firebaseOptions?: FirebaseOptions;
  name?: string;
};

export function FirebaseProvider({ children, firebaseOptions = {}, name = '[DEFAULT]' }: FirebaseProviderProps) {
  const [app, setApp] = useState<FirebaseApp | undefined>(DEFAULT_FIREBASE_VALUE.app);

  useEffect(() => {
    const firebaseOptionsValidated = firebaseOptionsSchema.parse(firebaseOptions);

    const apps = getApps();

    const existingApp = apps.find((app) => app.name === name);

    const app = existingApp || initializeApp(firebaseOptionsValidated);

    setApp(app);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FirebaseContext.Provider value={{ app }}>
      <>{children}</>
    </FirebaseContext.Provider>
  );
}
