'use client';

import { useCallback, useEffect, useState } from 'react';

import { Box } from './box';
import { Toast as RadixToast } from '../radix-ui/toast';
import { SpriteIcon } from '../material/components/sprites';
import { useEventBus } from '../hooks/use-event-bus';
import { z } from 'zod';
import { SystemStyleObject } from '../styled-system/types';

const TOAST_EVENT = 'toast';

const toastPropsSchema = z.object({
  actionText: z.string().optional(),
  actionAltText: z.string().optional(),
  description: z.string(),
  duration: z.number().optional(),
  onAction: z.function().returns(z.void()).optional(),
  onClose: z.function().returns(z.void()).optional(),
  showClose: z.boolean().optional().default(false),
  title: z.string().optional(),
  key: z.string(),
});

const toastEventSchema = toastPropsSchema.extend({ key: z.string() });

type ToastProps = z.input<typeof toastPropsSchema>;
type ToastEvent = z.input<typeof toastEventSchema>;

// type ToastProps = {
//   actionText?: string;
//   actionAltText: string;
//   description: string;
//   onAction?: () => void;
//   onClose?: () => void;
//   showClose?: boolean;
//   title?: string;
// };

let globalDispatch: ReturnType<typeof useEventBus>['dispatch'];

export function toast(event: ToastEvent) {
  if (globalDispatch) {
    globalDispatch(TOAST_EVENT, event);
  } else {
    console.info('🤖 <ToastViewport /> missing. Add to root layout.');
  }
}

/**
 * Inject into layout exactly one time. Let's not duplicate these things.
 */
type Toasts = Map<ToastEvent['key'], ToastEvent>;
type ToastViewportProps = { css?: SystemStyleObject; spritePath?: string };

export function ToastViewport({ css: cssObject = {}, spritePath = '/sprites/spritesheet.svg' }: ToastViewportProps) {
  const [toasts, setToasts] = useState<Toasts>(new Map());
  const { dispatch, on } = useEventBus();
  const addToast = useCallback(
    (toast: ToastEvent) =>
      setToasts((toasts) => {
        const updatedToasts = new Map(toasts.entries());

        updatedToasts.set(toast.key, toast);

        return updatedToasts;
      }),
    []
  );
  const removeToast = useCallback(
    (key: ToastEvent['key']) =>
      setToasts((toasts) => {
        const updatedToasts = new Map(toasts.entries());

        updatedToasts.delete(key);

        return updatedToasts;
      }),
    []
  );

  useEffect(() => {
    globalDispatch = dispatch;

    on(TOAST_EVENT, (e) => {
      const event = toastEventSchema.parse(e.detail);

      setToasts((toasts) => {
        const updatedToasts = new Map(toasts.entries());

        updatedToasts.set(event.key, event);

        return updatedToasts;
      });
    });
  }, [dispatch, on]);

  return (
    <RadixToast.Provider swipeDirection='right'>
      <RadixToast.Viewport
        css={{
          position: 'fixed',
          bottom: '4',
          right: '4',
          display: 'flex',
          flexDirection: 'column',
          gap: '3',
          zIndex: 1000,
          ...cssObject,
        }}
      />
      {Array.from(toasts.values()).map(({ key, ...toastProps }) => (
        <Toast
          key={key}
          {...toastProps}
          onClose={() => {
            removeToast(key);

            toastProps.onClose?.();
          }}
          spritePath={spritePath}
        />
      ))}
    </RadixToast.Provider>
  );
}

export function Toast({
  actionAltText,
  actionText,
  description,
  duration,
  onAction,
  onClose,
  showClose,
  spritePath = '/sprites/spritesheet.svg',
  title,
}: ToastProps & ToastViewportProps) {
  const [open, setOpen] = useState(true);

  return (
    <RadixToast.Root
      duration={duration}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);

        !isOpen && onClose && onClose();
      }}
      open={open}
    >
      {title && <RadixToast.Title>{title}</RadixToast.Title>}
      <RadixToast.Description asChild css={{ maxHeight: '20rem', overflowY: 'auto', wordBreak: 'break-all' }}>
        {description}
      </RadixToast.Description>
      <RadixToast.Actions>
        {showClose && (
          <RadixToast.Close>
            <SpriteIcon name='close' path={spritePath} size='xl' />
          </RadixToast.Close>
        )}

        <Box onClick={() => onAction?.()}>
          <RadixToast.Action altText={actionAltText || 'missing alt text'}>{actionText}</RadixToast.Action>
        </Box>
      </RadixToast.Actions>
    </RadixToast.Root>
  );
}
